import * as React from "react"
import MainLayout from "src/components/layoutmain";
import {Link} from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

/**
 * Page Mentions légales
 * @returns {JSX.Element}
 * @constructor
 */
export default function MentionsLegales() {
    const pageProps = {
        title: "Le live bar - Mentions légales",
        menuPageTitle: "Mentions légales",
        description: "Mentions Légales et politique de confidentialité",
        className: "mention-legale-container",
    };

    return (
        <MainLayout pageProps={pageProps}>
            <div className={"mention-legale"}>
                <div className={"bloc-category bloc-description"}>
                    <h1>Mentions Légales et politique de confidentialité</h1>
                    <p>
                        L'entreprise individuelle <span className={"bold"}>LE LIVE BAR</span>, soucieuse des
                        droits des individus, notamment au regard des traitements
                        automatisés et dans une volonté de transparence avec ses clients, a
                        mis en place une politique reprenant l’ensemble de ces traitements,
                        des finalités poursuivies par ces derniers ainsi que des moyens
                        d’actions à la disposition des individus afin qu’ils puissent au
                        mieux exercer leurs droits. Pour toute information complémentaire
                        sur la protection des données personnelles, nous vous invitons à
                        consulter le site de la{" "}
                        <a className={"duck-blue"} href="https://www.cnil.fr/">
                            CNIL
                        </a>
                    </p>
                    <p>
                        La poursuite de la navigation sur ce site vaut acceptation sans
                        réserve des dispositions et conditions d'utilisation qui suivent. La
                        version actuellement en ligne de ces conditions d'utilisation est la
                        seule opposable pendant toute la durée d'utilisation du site et
                        jusqu'à ce qu'une nouvelle version la remplace.
                    </p>
                </div>
                <div className={"bloc-category bloc-mentions-legales"}>
                    <h2>Mentions légales</h2>
                    <p>
                        <span className={"bold"}>Site :</span> LE LIVE BAR
                    </p>
                    <p>
                        <span className={"bold"}>Éditeur :</span>&nbsp;L'entreprise individuelle LE LIVE BAR
                        <br />
                        situé au 7 rue des etats, 4400 Nantes
                        <br />
                        immatriculée au R.C.S. Nantes sous le numéro 492922976. <br />
                        <span>Adresse mail :</span>{" "}
                        <a href="mailto:lelivebar@gmail.com" title={"Nous écrire par email"}>
                            lelivebar@gmail.com
                        </a>
                    </p>
                    <p>
                        <span className={"bold"}>Conception et développement :</span> LE LIVE BAR a été écoconçu par{" "}
                        <a href="https://www.numeko.fr/">Numeko</a>, société qui conçoit et développe des outils digitaux{" "}
                        durable et responsable dont le siège social est situé 22 avenue de Cornouaille - 44700 Orvault.
                    </p>
                    <p>
                        <span className={"bold"}>Hébergeur :</span> LE LIVE BAR est hébergé par{" "}
                        <a href="https://www.infomaniak.com/">Infomaniak</a>, un <a href="https://www.infomaniak.com/fr/hebergeur-ecologique">hébergeur écologique</a> dont le siège
                        social est situé Rue Eugène-Marziano 25, 1227 Genève, Suisse.
                    </p>
                </div>
                <div className={"bloc-category bloc-data-use-and-protect"}>
                    <h2 id={"consentement"}>Collecte et protection des données</h2>
                    <iframe
                      style={{border: 0, height: "230px", width: "100%"}}
                      title={"Matomo tracker consentement"}
                      src={"https://www.lelivebar.fr/analytics/index.php?module=CoreAdminHome&action=optOut&language=fr&backgroundColor=&fontColor=&fontSize=&fontFamily="}
                    />
                    <p>
                        LE LIVE BAR soucieux de la protection de ses utilisateurs et de leur vie privée, aucune données personnelles ne sont traitées par la société <strong>souhaitant
                        exempter ses utilisateurs de consentement&nbsp;: </strong>
                        <a href="https://www.cnil.fr/fr/cookies-solutions-pour-les-outils-de-mesure-daudience">
                            https://www.cnil.fr/fr/cookies-solutions-pour-les-outils-de-mesure-daudience
                        </a>.
                    </p>
                    <p>
                        Pour y répondre, LE LIVE BAR utilise à un logiciel recommandé par la CNIL pour sa protection
                        des données et sa conformité avec la loi française et européenne :
                        <a href="https://www.cnil.fr/fr/cookies-solutions-pour-les-outils-de-mesure-daudience"
                           alt={"Accéder au site du logiciel matomo"}> Matomo
                        </a>
                    </p>
                    <div className={"matomo-privacy"}>
                        <StaticImage
                          src={"../images/matomo.webp"}
                          alt={
                              "Image qui montre que l'on respecte la vie privée des utilisateurs avec le logiciel matomo"
                          }
                          placeholder={"none"}
                          quality={95}
                          width={250}
                        />
                    </div>
                    <p>
                        L'utilisation de ce logiciel permet notamment :
                        <br/>- Anonymiser les IP à 2 byte(s) - ex 192.168.xxx.xxx
                        <br/>- La prise en charge de "Ne pas suivre" (Do Not Track): <strong>Activé par Le live bar</strong>
                        <br/>- La mise en place d'une iframe de vie privée permettant à l'utilisateur de ne plus être suivi : <Link to="/mentions-legales#consentement" alt={"Accéder au consentement"}>ici</Link>.
                    </p>
                    <p>
                        Les seules données traitées sont utilisées pour mesurer l'audience du site dans le cadre
                        de l'amélioration continue de l'expérience utilisateur (mesure des performances,
                        détection de problèmes de navigation, optimisation des performances techniques ou
                        de son ergonomie, estimation de la puissance des serveurs nécessaires,
                        analyse des contenus consulté) et pour produire des données statistiques anonymes uniquement.
                    </p>
                    <p>
                        Les informations collectées ne sont conservées que pour une durée maximale de <strong>vingt-cinq mois</strong>.
                    </p>
                </div>
                <div className={"bloc-category bloc-cookies"}>
                    <h2>Cookies</h2>
                    <p>
                      Qu’est-ce qu’un « cookie » ? Un « Cookie » ou traceur est un fichier
                      électronique déposé sur un terminal (ordinateur, tablette,
                      smartphone,…) et lu par exemple lors de la consultation d'un site
                      internet, de la lecture d'un courrier électronique, de
                      l'installation ou de l'utilisation d'un logiciel ou d'une
                      application mobile et ce, quel que soit le type de terminal utilisé
                      (source : <a href="https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi">
                        https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi
                    </a>).
                        En naviguant sur ce site, des « cookies » émanant de la société responsable du site concerné
                        pourront être déposés sur votre terminal. Il s'agit uniquement de traceurs qui sont exemptés de consentement
                        (source : <a href="https://www.cnil.fr/fr/cookies-solutions-pour-les-outils-de-mesure-daudience">
                        https://www.cnil.fr/fr/cookies-solutions-pour-les-outils-de-mesure-daudience</a>).
                    </p>
                    <p>
                      Les informations traitées sont utilisées pour mesurer l'audience du site dans le cadre
                      de l'amélioration continue de l'expérience utilisateur (mesure des performances,
                      détection de problèmes de navigation, optimisation des performances techniques ou
                      de son ergonomie, estimation de la puissance des serveurs nécessaires,
                      analyse des contenus consulté) et pour produire des données statistiques anonymes uniquement.
                    </p>
                    <p>La durée de vie de ces cookies est de <strong>treize mois</strong>.</p>
                    <p>
                      Pour plus d’informations sur l’utilisation, la gestion et la
                      suppression des « cookies », pour tout type de navigateur, nous vous
                      invitons à consulter le lien suivant :{" "}
                      <a href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser">
                        https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser
                      </a>
                    </p>
                </div>
                <div className={"bloc-category bloc-access-site"}>
                    <h2>Accès au site</h2>
                    <p>
                        L'accès au site et son utilisation sont réservés à un usage
                        strictement personnel. Vous vous engagez à ne pas utiliser ce site
                        et les informations ou données qui y figurent à des fins
                        commerciales, politiques, publicitaires et pour toute forme de
                        sollicitation commerciale et notamment l'envoi de courriers
                        électroniques non sollicités.
                    </p>
                </div>
                <div className={"bloc-category bloc-content-site"}>
                    <h2>Contenu du site</h2>
                    <p>
                        Toutes les marques, photographies, textes, commentaires,
                        illustrations, images animées ou non, séquences vidéo, sons, ainsi
                        que toutes les applications informatiques qui pourraient être
                        utilisées pour faire fonctionner ce site et plus généralement tous
                        les éléments reproduits ou utilisés sur le site sont protégés par
                        les lois en vigueur au titre de la propriété intellectuelle. Ils
                        sont la propriété pleine et entière de l'éditeur ou de ses
                        partenaires. Toute reproduction, représentation, utilisation ou
                        adaptation, sous quelque forme que ce soit, de tout ou partie de ces
                        éléments, y compris les applications informatiques, sans l'accord
                        préalable et écrit de l'éditeur, sont strictement interdites. Le
                        fait pour l'éditeur de ne pas engager de procédure dès la prise de
                        connaissance de ces utilisations non autorisées ne vaut pas
                        acceptation desdites utilisations et renonciation aux poursuites.
                    </p>
                </div>
                <div className={"bloc-category bloc-management-site"}>
                    <h2>Gestion du site</h2>
                    <p>
                        Pour la bonne gestion du site, l'éditeur pourra à tout moment :{" "}
                        <br />
                        - suspendre, interrompre ou limiter l'accès à tout ou partie du
                        site, réserver l'accès au site, ou à certaines parties du site, à
                        une catégorie déterminée d'internautes ; <br />
                        - supprimer toute information pouvant en perturber le fonctionnement
                        ou entrant en contravention avec les lois nationales ou
                        internationales ;<br />- suspendre le site afin de procéder à des
                        mises à jour.
                    </p>
                </div>
                <div className={"bloc-category bloc-responsibility"}>
                    <h2>Responsabilités</h2>
                    <p>
                        La responsabilité de l'éditeur ne peut être engagée en cas de
                        défaillance, panne, difficulté ou interruption de fonctionnement,
                        empêchant l'accès au site ou à une de ses fonctionnalités. Le
                        matériel de connexion au site que vous utilisez est sous votre
                        entière responsabilité. Vous devez prendre toutes les mesures
                        appropriées pour protéger votre matériel et vos propres données
                        notamment d'attaques virales par Internet. Vous êtes par ailleurs
                        seul responsable des sites et données que vous consultez.
                    </p>
                    <p>
                        L'éditeur ne pourra être tenu responsable en cas de poursuites
                        judiciaires à votre encontre : <br />
                        - du fait de l'usage du site ou de tout service accessible via
                        Internet ;<br />- du fait du non-respect par vous des présentes
                        conditions générales.
                    </p>
                    <p>
                        L'éditeur n'est pas responsable des dommages causés à vous-même, à
                        des tiers et/ou à votre équipement du fait de votre connexion ou de
                        votre utilisation du site et vous renoncez à toute action contre lui
                        de ce fait. Si l'éditeur venait à faire l'objet d'une procédure
                        amiable ou judiciaire en raison de votre utilisation du site, il
                        pourra se retourner contre vous pour obtenir l'indemnisation de tous
                        les préjudices, sommes, condamnations et frais qui pourraient
                        découler de cette procédure.
                    </p>
                </div>
                <div className={"bloc-category bloc-link"}>
                    <h2>Liens hypertextes</h2>
                    <p>
                        La mise en place par les utilisateurs de tous liens hypertextes vers
                        tout ou partie du site est autorisée par l'éditeur. Tout lien devra
                        être retiré sur simple demande de l'éditeur. Toute information
                        accessible via un lien vers d'autres sites n'est pas publiée par
                        l'éditeur. L'éditeur ne dispose d'aucun droit sur le contenu présent
                        dans ledit lien.
                    </p>
                </div>
                <div className={"bloc-category bloc-sharing-data-third-parties"}>
                  <h2>Partage des données personnelles avec des tiers</h2>
                  <p>
                    Aucune données ne sont partagées avec des tiers.
                  </p>
                </div>
                <div className={"bloc-category"}>
                    <h2>Photographies et représentation des produits</h2>
                    <p>
                        Les photographies de produits, accompagnant leur description, ne sont pas contractuelles et n'engagent pas l'éditeur.
                    </p>
                </div>
                <div className={"bloc-category"}>
                    <h2>Loi applicable</h2>
                    <p>
                        Les présentes conditions d'utilisation du site sont régies par la
                        loi française et soumises à la compétence des tribunaux du siège
                        social de l'éditeur, sous réserve d'une attribution de compétence
                        spécifique découlant d'un texte de loi ou réglementaire particulier.
                    </p>
                </div>
                <div className={"bloc-category"}>
                    <h2>Contactez-nous</h2>
                    <p>
                        Pour toute question, information sur les produits présentés sur le
                        site, ou concernant le site lui-même, vous pouvez laisser un message
                        à l'adresse suivante :{" "}
                        <a href="mailto:lelivebar@gmail.com" title={"Nous écrire par email"}>
                            lelivebar@gmail.com
                        </a>
                        .
                    </p>
                </div>
            </div>
        </MainLayout>
    )
}